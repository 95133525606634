@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navSidebarWidth: 12.5rem;
  --navSidebarWidthCollapsed: 1.25rem;
  --easingFnSwing: cubic-bezier(0.87, 0, 0.13, 1);
  --easingFnOutQuint: cubic-bezier(0.16, 1, 0.3, 1);
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('/static/fonts/Inter-Light.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-Light.woff?v=3.18') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src:
    url('/static/fonts/Inter-LightItalic.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-LightItalic.woff?v=3.18') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/static/fonts/Inter-Regular.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-Regular.woff?v=3.18') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    url('/static/fonts/Inter-Italic.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-Italic.woff?v=3.18') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('/static/fonts/Inter-Medium.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-Medium.woff?v=3.18') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src:
    url('/static/fonts/Inter-MediumItalic.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-MediumItalic.woff?v=3.18') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('/static/fonts/Inter-SemiBold.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-SemiBold.woff?v=3.18') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src:
    url('/static/fonts/Inter-SemiBoldItalic.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-SemiBoldItalic.woff?v=3.18') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('/static/fonts/Inter-Bold.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-Bold.woff?v=3.18') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    url('/static/fonts/Inter-BoldItalic.woff2?v=3.18') format('woff2'),
    url('/static/fonts/Inter-BoldItalic.woff?v=3.18') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/static/fonts/roboto-mono-regular.woff2') format('woff2'),
    url('/static/fonts/roboto-mono-regular.woff?v=3.18') format('woff');
}

.container-sm {
  width: 750px;
}

.selected-table {
  outline: 1px solid #0043ce;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

html *:focus {
  outline-color: #0f62fe;
  outline-offset: -1px;
  outline-style: solid;
  outline-width: 2px;
}

/** To preserve intended behavior of the global focus state
    defined above, we globally disable the Tailwind-specific focus ring 
**/
@layer components {
  html * {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}

@media print {
  @page {
    margin: 0;
    size: auto;
  }
  body {
    margin: 1.6cm;
  }
}

/* built-in tailwind animation keyframes: */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* custom animation keyframes */

@keyframes keypress {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

@keyframes accordionDown {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
}

@keyframes accordionUp {
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
  to {
    height: 0px;
    opacity: 0;
  }
}

@keyframes collapseDown {
  from {
    height: 0px;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes collapseUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0px;
  }
}

@keyframes indeterminateLoading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes load {
  0%,
  100% {
    height: 20px;
  }
  50% {
    height: 0;
  }
}

@keyframes flashInOut {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.p-app {
  padding: 1rem 1rem;
}

.m-app {
  margin: 1rem 1rem;
}

.pt-app {
  padding-top: 1rem;
}

.pl-app {
  padding-left: 1rem;
}

.pr-app {
  padding-left: 1rem;
}

.pb-app {
  padding-bottom: 1rem;
}

.px-app {
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav-node-block {
  justify-content: flex-start;
  padding-left: 0.5rem;
}

.selected .nav-node-block::before {
  background-color: #bae6ff;
}

.nav-node-block::before {
  width: 16px;
  height: 16px;
  content: '';
  background-color: #0072c3;
  border-radius: 4px;
}

/** For tooltips **/
[data-popper-placement^='top'] .arrow-triangle {
  bottom: -2px;
}

[data-popper-placement^='bottom'] .arrow-triangle {
  top: -2px;
}

[data-popper-placement^='left'] .arrow-triangle {
  right: -2px;
}

[data-popper-placement^='right'] .arrow-triangle {
  left: -2px;
}

[data-popper-placement^='top'] .arrow-triangle-large {
  bottom: -8px;
}

[data-popper-placement^='bottom'] .arrow-triangle-large {
  top: -8px;
}

[data-popper-placement^='left'] .arrow-triangle-large {
  right: -8px;
}

[data-popper-placement^='right'] .arrow-triangle-large {
  left: -8px;
}

@layer base {
  [type='checkbox']:focus,
  [type='radio']:focus,
  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    outline: none;
    box-shadow: none;
  }

  .break-word {
    word-break: break-word;
  }
}

@layer utilities {
  /* Style the scrollbar to be always visible and prominent.
  From https://stackoverflow.com/a/71893788 */
  .big-scrollbar::-webkit-scrollbar {
    width: 2em;
  }

  .big-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .big-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
  }

  .big-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Force show scrollbar to let users know that the list is scrollable */
  .force-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  .force-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: theme('colors.gray.400');
  }

  .force-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: theme('colors.gray.600');
  }

  .border-bottom {
    /*
     Tailwind doesn't give us an option to overwrite the shorthand border property, which is added by
     Material-UI and takes precedence over other border-related CSS properties. Therefore, I had to
     fallback to a custom utility class for this case.
    */
    border: inherit;
    border-bottom: solid 1px theme('colors.coolGray.300');
  }
}
