@import './customNumberInput.css';
@import './customDatepicker.css';

.popoverContent {
    max-height: var(--radix-popover-content-available-height);
    transform-origin: var(--radix-popover-content-transform-origin);
    animation: scaleIn 0.15s ease-out;
}
  
@keyframes scaleIn {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
}