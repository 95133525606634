.DateRangePickerInput {
  height: 32px;
  border-radius: 3px;
  position: relative;
}

.DateRangePickerInput::before {
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  content: '';
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 2px;
  border-radius: 2px;
  width: 17px;
  height: 32px;
  position: absolute;
  z-index: 2;
  right: 10px;
}

.DateInput {
  height: 100%;
  width: 90px;
}

.DateInput:last-child {
  width: 150px;
}

.DateInput_input {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  height: 100%;
  margin: 0;
  padding: 0 10px;
  border: 0;
}

.DateInput_input__focused {
  border: 0;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #6d08a3;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b3b2f1;
  border: 1px double #b3b2f1;
  color: #410087;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background-color: #6d08a3;
  border-color: #6d08a3;
}

.CalendarDay__selected_span {
  background-color: #a014ea;
  border-color: #a014ea;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border: 1px double #a014ea;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #a014ea;
}
